<template>
    <div style="width: 1000px;min-height:100vh;display: flex;justify-content: center;align-items: center;margin: 0 auto;">
        <div style="margin-top: 120px;">
            <div style="width: 1000px;min-height:700px;background: #FFFFFF">
                <div style="clear: both;display: flex;align-items: center;padding-top: 40px;">
                    <div style="font-size: 25px;color:#444444;font-weight:bold;display: flex;justify-content: center;flex: 1;">招聘信息</div>
                </div>
                <div style="clear: both;padding-top: 30px;">
                    <div style="width: 920px;height: 10px;background: #FF9800;border-radius: 20px 20px 0px 0px;margin: 0 auto;"></div>
                    <div class="gradient-border" style="width: 916px;min-height: 450px;clear: both;margin: 0 auto;box-shadow: 0px 2px 6px 1px rgba(0,0,0,0.16);">
                        <teble style="width: 100%;font-size: 14px;">
                            <tr class="typeface">
                                <th style=" width: 100px;height: 20px;padding: 23px 42.5px;font-size: 17px; color: #444444;border-left:1px solid #808080;border-right:1px solid #CFCFCF;font-weight: bold;border-bottom:1px solid #CFCFCF;border-left: none">职位名称</th>
                                <td style=" width: 260px;font-size: 17px;height: 20px;color: #444444;padding: 10px;border-left: none;border-bottom:1px solid #CFCFCF;border-top: none;border-right:1px solid #CFCFCF;">{{content.fd_1677729356192}}</td>
                                <th style=" width: 100px;height: 20px;padding: 23px 41px;font-size: 17px; color: #444444;font-weight: bold;border-left: none;border-bottom:1px solid #CFCFCF;border-top: none;border-right:1px solid #CFCFCF;">工作性质</th>
                                <td style=" width: 260px;font-size: 17px;height: 20px;color: #444444;padding: 10px;border-left: none;border-bottom:1px solid #CFCFCF;border-top: none;border-right:1px solid #808080;border-right: none">{{content.fd_1704346284771}}</td>
                            </tr>
                        </teble>
                        <teble style="width: 100%;font-size: 14px;">
                            <tr class="typeface">
                                <th style=" width: 100px;height: 20px;padding: 23px 42.5px;font-size: 17px; color: #444444;border-left:1px solid #808080;border-right:1px solid #CFCFCF;font-weight: bold;border-bottom:1px solid #CFCFCF;border-left: none">薪资待遇</th>
                                <td style=" width: 260px;font-size: 17px;height: 20px;color: #444444;padding: 10px;border-left: none;border-bottom:1px solid #CFCFCF;border-top: none;border-right:1px solid #CFCFCF;">{{content.fd_1704346319766}}</td>
                                <th style=" width: 100px;height: 20px;padding: 23px 41px;font-size: 17px; color: #444444;font-weight: bold;border-left: none;border-bottom:1px solid #CFCFCF;border-top: none;border-right:1px solid #CFCFCF;">最低学历</th>
                                <td style=" width: 260px;font-size: 17px;height: 20px;color: #444444;padding: 10px;border-left: none;border-bottom:1px solid #CFCFCF;border-top: none;border-right:1px solid #808080;border-right: none">{{content.fd_1704346320955}}</td>
                            </tr>
                        </teble>
                        <teble style="width: 100%;font-size: 14px;">
                            <tr class="typeface">
                                <th style=" width: 100px;height: 20px;padding: 23px 42.5px;font-size: 17px; color: #444444;border-left:1px solid #808080;border-right:1px solid #CFCFCF;font-weight: bold;border-bottom:1px solid #CFCFCF;border-left: none">招聘人数</th>
                                <td style=" width: 260px;font-size: 17px;height: 20px;color: #444444;padding: 10px;border-left: none;border-bottom:1px solid #CFCFCF;border-top: none;border-right:1px solid #CFCFCF;">{{content.fd_1704346380373}}人</td>
                                <th style=" width: 100px;height: 20px;padding: 23px 41px;font-size: 17px; color: #444444;font-weight: bold;border-left: none;border-bottom:1px solid #CFCFCF;border-top: none;border-right:1px solid #CFCFCF;">截止日期</th>
                                <td style=" width: 260px;font-size: 17px;height: 20px;color: #444444;padding: 10px;border-left: none;border-bottom:1px solid #CFCFCF;border-top: none;border-right:1px solid #808080;border-right: none">{{content.fd_1704346986417}}</td>
                            </tr>
                        </teble>
                        <table border="1" style="width: 100%;height: 50px;overflow: hidden;font-size: 14px;border: none;">
                            <tr class="typeface">
                                <th class="title">工作地点</th>
                                <td class="content">{{content.fd_1704346900772}}</td>
                            </tr>
                            <tr class="typeface">
                                <th class="title">岗位职责</th>
                                <td class="content">{{content.fd_1704346760268}}</td>
                            </tr>
                            <tr class="typeface">
                                <th class="title">任职要求</th>
                                <td class="content">{{content.fd_1704346801983}}</td>
                            </tr>
                            <tr class="typeface">
                                <th class="title" style="border-bottom: none">备注</th>
                                <td class="content" style="border-bottom: none">{{content.fd_1704346862954}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {

    },

    computed: {

    },
    data () {
        return {
            content:{},
        }
    },
    mounted:function(){
        document.documentElement.scrollTop = document.body.scrollTop = 0;
        this.getRoomContent()
    },
    methods:{
        // 获取内容
        getRoomContent() {
            this.newApi.getRoomContentInfo({id: this.$route.params.id}).then(res =>{
                this.content = res.data.content;
            })
        },

    }
}
</script>
<style scoped>
.title {
    width: 100px;
    height: 20px;
    padding: 23px 26px;
    font-size: 17px;
    color: #444444;
    border-bottom:1px solid #CFCFCF;
    font-weight: bold;
    border-right:1px solid #CFCFCF;
    border-left: none;
    border-top: none;
    font-family:'SimHei';

}
.content {
    font-size: 17px;
    height: 20px;
    color: #444444;
    width: 80%;
    border-bottom:1px solid #CFCFCF;
    border-top: none;
    border-left: none;
    border-right: none;
    padding: 10px;
}
.typeface {
    font-family:'SimHei';
    width: 100%
}
.gradient-border {
    border-right: 2px solid #ddd;
    border-left: 2px solid #ddd;
    border-image: linear-gradient(rgb(255, 152, 0), #FFFFFF) 30 30;
}
</style>